import axios from "axios";

const instance = axios.create({
  baseURL: "https://apis.findsparrow.com/api",
  headers: {
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  },
});

// const instance = axios.create({
//   baseURL: "http://localhost/sparrow-backend/api",
//   headers: {
//     "Cache-Control": "no-cache",
//     Pragma: "no-cache",
//     Expires: "0",
//   },
// });

export default instance;
